@import "core/index.scss";
.section.home-horizontalbanners {
	.horizontalbanners-list {

	}

		.list-banner {
			+ .list-banner {
				margin-top: 1rem;
			}
		}

			.banner-link {
				position: relative;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				height: 13rem;
				border-radius: $radius-general;
				overflow: hidden;

				&.dark {
					color: $color-white;
				}
			}

			.banner-text {
				position: relative;
				z-index: 2;
				padding: 1rem 18rem 1rem 1.5rem;
				font-size: 1.2rem;
				line-height: 1.2em;
			}

				.text-title {
					@include font-bold;
					font-size: 2rem;

					+ .text-subtitle {
						margin-top: .5rem;
						display: block;
					}
				}

			.banner-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: $radius-general;
			}
}